<template>
  <Layout>
    <div class='base-width'>
      <div class='c-card p30 mt30 mb30'>
        <h1>按钮</h1>
        <div class='s16'>
          <br>
          <span>小型按钮（30px高）</span>
          <BaseButton class="m5" color='blue' size='mini'>按钮</BaseButton>
          <BaseButton class="m5" color='green' size='mini'>按钮</BaseButton>
          <BaseButton class="m5" color='red' size='mini'>按钮</BaseButton>
          <BaseButton class="m5" color='brown' size='mini'>按钮</BaseButton>
          <br>
          <span>中等按钮（40px高）</span>
          <BaseButton class="m5" color='blue'>按钮</BaseButton>
          <BaseButton class="m5" color='green'>按钮</BaseButton>
          <BaseButton class="m5" color='red'>按钮</BaseButton>
          <BaseButton class="m5" color='brown'>按钮</BaseButton>
          <br>
          <span>大型按钮（50px高）</span>
          <BaseButton class="m5" color='blue' size='large'>按钮</BaseButton>
          <BaseButton class="m5" color='green' size='large'>按钮</BaseButton>
          <BaseButton class="m5" color='red' size='large'>按钮</BaseButton>
          <BaseButton class="m5" color='brown' size='large'>按钮</BaseButton>
          <br>
          <span>边框样式</span>
          <BaseButton class="m5" color='blue' styleType='border'>按钮</BaseButton>
          <BaseButton class="m5" color='green' styleType='border'>按钮</BaseButton>
          <BaseButton class="m5" color='red' styleType='border'>按钮</BaseButton>
          <BaseButton class="m5" color='brown' styleType='border'>按钮</BaseButton>
          <br>
          <span>浅色样式</span>
          <BaseButton class="m5" color='blue' styleType='light'>按钮</BaseButton>
          <BaseButton class="m5" color='green' styleType='light'>按钮</BaseButton>
          <BaseButton class="m5" color='red' styleType='light'>按钮</BaseButton>
          <BaseButton class="m5" color='brown' styleType='light'>按钮</BaseButton>
          <br>
          <span>指定鼠标悬浮颜色</span>
          <BaseButton class="m5" color='blue' hover='green'>绿色</BaseButton>
          <BaseButton class="m5" color='green' hover='blue'>蓝色</BaseButton>
          <BaseButton class="m5" color='red' hover='brown'>棕色</BaseButton>
          <BaseButton class="m5" color='brown' hover='red'>红色</BaseButton>
          <br>
          <span>图标：箭头</span>
          <BaseButton class="m5" icon='right' color='blue'>按钮</BaseButton>
          <BaseButton class="m5" icon='right' color='blue' styleType='border'>按钮</BaseButton>
          <BaseButton class="m5" icon='right' color='green' styleType='border'>按钮</BaseButton>
          <BaseButton class="m5" icon='right' color='red' styleType='border'>按钮</BaseButton>
          <BaseButton class="m5" icon='right' color='brown' styleType='border'>按钮</BaseButton>
          <BaseButton class="m5" icon='right' color='blue' styleType='light'>按钮</BaseButton>
          <BaseButton class="m5" icon='right' color='green' styleType='light'>按钮</BaseButton>
          <BaseButton class="m5" icon='right' color='red' styleType='light'>按钮</BaseButton>
          <BaseButton class="m5" icon='right' color='brown' styleType='light'>按钮</BaseButton>
        </div>
        <br>
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang='scss' scoped>
.p30{ padding: 0.3rem; }
.m30{ margin: 0.3rem; }
.mt30{ margin-top: .3rem; }
.mb30{ margin-bottom: 0.3rem; }
.m5{
  margin: .05rem;
}
</style>